<template>
  <div>
    <div class="search">
      <div class="title">奖励列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="手机号:">
          <el-input v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item label="活动Id:" v-if="type=='upgrade'">
          <el-input v-model="params.baseId"></el-input>
        </el-form-item>
        <el-form-item label="活动Id:" v-else>
          <el-input v-model="params.setId"></el-input>
        </el-form-item>
        
        <!--  :picker-options="pickerOptions0" -->
        <el-form-item label="奖励时间:">
          <el-date-picker
            :editable="false"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="inquireDate"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="店长等级:" v-if="type=='upgrade'">
        <el-select v-model="params.levelId" placeholder="请选择">
          <el-option
            v-for="item in profitLevelList"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
      </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="exportOrderPrize()"
            >生成报表</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column prop="baseId" align="center" label="活动ID" width="60" v-if="type =='upgrade'">
      </el-table-column>
      <el-table-column prop="setId" align="center" label="活动ID" width="60" v-else>
      </el-table-column>
      <el-table-column prop="prizeId" align="center" label="奖励ID" width="60">
      </el-table-column>

      <el-table-column
        prop="setName"
        align="center"
        label="活动名称"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="levelTitle"
        align="center"
        label="等级名称"
        width="80"
        v-if="type =='upgrade'"
      >
      </el-table-column>
      <el-table-column
        prop="goalStrType"
        align="center"
        label="商品类型"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="prodName"
        label="商品名称"
        align="center"
        width="200"
      >
      </el-table-column>

      <el-table-column
        prop="fatherName"
        label="	商品上级名称"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column label="展示图 " width="100" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 70px; height: 70px"
            :src="scope.row.showImg"
            :preview-src-list="[scope.row.showImg]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="addTime"
        label="奖励时间"
        width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="phone"
        label="领取人手机号"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="strStatus"
        label="当前状态"
        width="70"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="drawTime"
        label="领取有效期"
        width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="useEndTime"
        label="使用截止时间"
        width="120"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="trueName"
        label="领取人真实姓名"
        width="90"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="goalCount" label="数量" width="50" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button type="primary" size="mini" @click="remove(scope.row)"
              >删除</el-button
            > -->
          <!-- <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button> -->
          <template v-if="type == 'order'">
            <el-button
              type="primary"
              size="mini"
              @click="checkCommodity(scope.row)"
              v-if="
                scope.row.status == 3 &&
                $store.state.powerList.indexOf('invite:order:prize:modify') !==
                  -1
              "
              >审核</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="overTime(scope.row)"
              v-if="
                scope.row.status == 6 &&
                $store.state.powerList.indexOf('invite:order:prize:update') !==
                  -1
              "
              >延长时间</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="overUseTime(scope.row)"
              v-if="
                scope.row.status == 8 &&
                $store.state.powerList.indexOf('invite:order:prize:useTime') !==
                  -1
              "
              >延长使用时间</el-button
            >
          </template>
          <template v-if="type == 'register'">
            <el-button
              type="primary"
              size="mini"
              @click="checkCommodity(scope.row)"
              v-if="
                scope.row.status == 3 &&
                $store.state.powerList.indexOf('invite:user:prize:modify') !==
                  -1
              "
              >审核</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="overTime(scope.row)"
              v-if="
                scope.row.status == 6 &&
                $store.state.powerList.indexOf('invite:user:prize:update') !==
                  -1
              "
              >延长时间</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="overUseTime(scope.row)"
              v-if="
                scope.row.status == 8 &&
                $store.state.powerList.indexOf('invite:user:prize:useTime') !==
                  -1
              "
              >延长使用时间</el-button
            >
          </template>
          <template v-if="type == 'consumption'">
            <el-button
              type="primary"
              size="mini"
              @click="checkCommodity(scope.row)"
              v-if="
                scope.row.status == 3 &&
                $store.state.powerList.indexOf('invite:fee:prize:modify') !== -1
              "
              >审核</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="overTime(scope.row)"
              v-if="
                scope.row.status == 6 &&
                $store.state.powerList.indexOf('invite:fee:prize:update') !== -1
              "
              >延长时间</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="overUseTime(scope.row)"
              v-if="
                scope.row.status == 8 &&
                $store.state.powerList.indexOf('invite:fee:prize:useTime') !==
                  -1
              "
              >延长使用时间</el-button
            >
          </template>
          <template v-if="type == 'upgrade'">
            <el-button
              type="primary"
              size="mini"
              @click="checkCommodity(scope.row)"
              v-if="
                scope.row.status == 3 &&
                $store.state.powerList.indexOf('distribution:level:prize:modify') !== -1
              "
              >审核</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="overTime(scope.row)"
              v-if="
                scope.row.status == 6 &&
                $store.state.powerList.indexOf('distribution:level:prize:update') !== -1
              "
              >延长时间</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="overUseTime(scope.row)"
              v-if="
                scope.row.status == 8 &&
                $store.state.powerList.indexOf('distribution:level:prize:useTime') !==
                  -1
              "
              >延长使用时间</el-button
            >
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
                <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
              <el-button type="primary" @click="allOpe">批量操作</el-button>
           </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="填写拒绝原因"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="refusalReason" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="拒绝原因:" prop="reason">
          <el-input v-model="refusalReason.reason" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="延长时间"
      :visible.sync="dialogVisibleTime"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="延长时间:" prop="reason">
          <el-date-picker
            v-model="endTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTime = false">取 消</el-button>
        <el-button type="primary" @click="onConfirmEndTime">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="延长使用时间"
      :visible.sync="dialogVisibleUseTime"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="延长使用时间:" prop="reason">
          <el-date-picker
            v-model="endUseTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleUseTime = false">取 消</el-button>
        <el-button type="primary" @click="onConfirmUseEndTime">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import {
  getorderPrizeList,
  updateorderPrizeSetStatus,
  updateEndTime,
  updateUseEndTime,
  exportOrderPrizeList,
  getorderPrizeListRegister,
  updateorderPrizeSetStatusRegister,
  updateEndTimeRegister,
  updateUseEndTimeRegister,
  exportOrderPrizeListRegister,
  getorderPrizeListConsumption,
  updateorderPrizeSetStatusConsumption,
  updateEndTimeConsumption,
  updateUseEndTimeConsumption,
  exportOrderPrizeListConsumption,
  getorderPrizeListUpgrade,
  updateorderPrizeSetStatusUpgrade,
  updateEndTimeUpgrade,
  updateUseEndTimeUpgraden,
  exportOrderPrizeListUpgrade,
} from "../../api/invite.js";
import { gradeListApi } from "../../api/wanxiaoShop";
export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisibleTime: false,
      dialogVisibleUseTime: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 10,
        phone: "",
        beginTime: "",
        endTime: "",
        setId: "",
        baseId:null,
        levelId:null
        // phone: "",
        // beginTime: "",
        // endTime: "",
      },
      refusalReason: {
        reason: "",
      },
      addRules: {
        reason: [
          {
            required: true,
            message: "请输入拒绝原因",
            trigger: ["blur", "change"],
          },
        ],
      },
      tableData: [],
      inquireDate: [],
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      prizeId: "",
      endTime: "",
      endUseTime: "",
      type: "",
      profitLevelList:[]
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.getList();
    if(this.type == 'upgrade'){
      this.getProList()
    }
  },
  watch: {
    $route(to, from) {
      this.type = this.$route.query.type;
      this.params.setId = null
      this.params.baseId = null;
      this.params.levelId = null;
      this.getList();
      if(this.type == 'upgrade'){
      this.getProList()
    }
    },
  },
  methods: {
    //获取店长等级
    async getProList() {
      const { data } = await gradeListApi();
      if (data.code == 0) {
        this.profitLevelList = data.data;
      }
    },
    remove(row) {
      this.$confirm("此操作将删除该奖励, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await prizeDel({ goalId: row.goalId });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    checkCommodity(obj) {
      console.log(obj);
      this.prizeId = obj.prizeId;
      this.$confirm("请选择通过还是拒绝此奖励, 是否继续?", "提示", {
        confirmButtonText: "通过",
        cancelButtonText: "拒绝",
        type: "warning",
      })
        .then(async (res) => {
          let params = {
            prizeId: this.prizeId,
            status: 1,
          };
          let demaad =
            this.type == "order"
              ? updateorderPrizeSetStatus
              : this.type == "register"
              ? updateorderPrizeSetStatusRegister
              : this.type == "consumption"
              ? updateorderPrizeSetStatusConsumption
              : updateorderPrizeSetStatusUpgrade;

          const { data } = await demaad(params);
          if (data.code == 0) {
            this.$message.success("操作成功");
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已拒绝",
          // });
          this.dialogVisible = true;
        });
    },
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      console.log(this.inquireDate);
      if (this.inquireDate == null) {
        this.inquireDate = [];
      }
      this.params.beginTime = this.inquireDate[0] || "";
      this.params.endTime = this.inquireDate[1] || "";
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      let demaad =
        this.type == "order"
          ? getorderPrizeList
          : this.type == "register"
          ? getorderPrizeListRegister
          : this.type == "consumption"
          ? getorderPrizeListConsumption
          : getorderPrizeListUpgrade;
      const { data } = await demaad(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
        this.tableData.forEach((item) => {
          item.drawTime = item.beginTime + "-" + item.endTime;
        });
      } else {
      }
    },
    async exportOrderPrize() {
      if (this.inquireDate == null) {
        this.inquireDate = [];
      }
      this.params.beginTime = this.inquireDate[0] || "";
      this.params.endTime = this.inquireDate[1] || "";
      let demaad =
        this.type == "order"
          ? exportOrderPrizeList
          : this.type == "register"
          ? exportOrderPrizeListRegister
          : this.type == "consumption"
          ? exportOrderPrizeListConsumption
          : exportOrderPrizeListUpgrade;
      const { data } = await demaad(this.params);
      let date = +new Date();
      let url = window.URL.createObjectURL(data); //转换文件流未url
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      if (this.type == "order") {
        link.setAttribute(
          "download",
          `万旅网小程序V2.0邀请下单管理-奖励列表报表_${date}.xls`
        );
      } else if (this.type == "register") {
        link.setAttribute(
          "download",
          `万旅网小程序V2.0邀请注册管理-奖励列表报表_${date}.xls`
        );
      } else if (this.type == "consumption") {
        link.setAttribute(
          "download",
          `万旅网小程序V2.0消费管理-奖励列表报表_${date}.xls`
        );
      } else {
        link.setAttribute(
          "download",
          `万旅网小程序V2.0分销等级-奖励列表报表_${date}.xls`
        );
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
    onConfirm() {
      this.$refs.addRuleFormRef.validate(async (valid) => {
        if (valid) {
          let params = {
            prizeId: this.prizeId,
            status: 2,
            reason: this.refusalReason.reason,
          };
          let demaad =
            this.type == "order"
              ? updateorderPrizeSetStatus
              : this.type == "register"
              ? updateorderPrizeSetStatusRegister
              : this.type == "consumption"
              ? updateorderPrizeSetStatusConsumption
              : updateorderPrizeSetStatusUpgrade;
          const { data } = await demaad(params);
          if (data.code == 0) {
            this.$message.success("操作成功");
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message.error(data.msg);
          }
        } else {
          return false;
        }
      });
    },
    overTime(obj) {
      this.dialogVisibleTime = true;
      this.prizeId = obj.prizeId;
    },
    async onConfirmEndTime() {
      console.log(this.endTime);
      if (this.endTime == "") {
        this.$message.warning("请选择时间");
        return;
      }
      let params = {
        endTime: this.endTime,
        prizeId: this.prizeId,
      };
      let demaad =
        this.type == "order"
          ? updateEndTime
          : this.type == "register"
          ? updateEndTimeRegister
          : this.type == "consumption"
          ? updateEndTimeConsumption
          : updateEndTimeUpgrade;
      const { data } = await demaad(params);
      if (data.code == 0) {
        this.$message.success("操作成功");
        this.dialogVisibleTime = false;
        this.getList();
      }
    },
    overUseTime(obj) {
      this.dialogVisibleUseTime = true;
      this.prizeId = obj.prizeId;
    },
    async onConfirmUseEndTime() {
      if (this.endUseTime == "") {
        this.$message.warning("请选择时间");
        return;
      }
      let params = {
        endTime: this.endUseTime,
        prizeId: this.prizeId,
      };
      let demaad =
        this.type == "order"
          ? updateUseEndTime
          : this.type == "register"
          ? updateUseEndTimeRegister
          : this.type == "consumption"
          ? updateUseEndTimeConsumption
          : updateUseEndTimeUpgraden;
      const { data } = await demaad(params);
      if (data.code == 0) {
        this.$message.success("操作成功");
        this.dialogVisibleUseTime = false;
        this.getList();
      }
    },
  },
};
</script>
  
  <style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
  